import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "react-icons-kit"
import { facebookSquare } from "react-icons-kit/fa/facebookSquare"
import { instagram } from "react-icons-kit/fa/instagram"
import NavBar from "../components/NavBar"
import Carousel from "../components/Carousel"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "en-US",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | social media"
        description="Social media"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-1" />
            <div className="col-span-10">
              <h1>Social media</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8">
              <p>
                <b>What we do:</b>
              </p>
              <p>account opening</p>
              <p>content generation</p>
              <p>advertising management</p>
              <div className="h-10" />
              <p>
                <b>Open your account</b>
              </p>
              <p>
                Once you have a website working you need to attract trafic to
                it.
              </p>
              <p>
                The social networks play an important role in this request and
                it is very recommendable to take it wisely.
              </p>
              <p>
                It is very common that a web user checks out the links to social
                networks to verify if it is "alive", that is, with periodic
                posts and interactions with users.
              </p>
              <p>
                In the same way, someone that finds your social network account
                will like to verify your website to have an overview and more
                information in a more formal way.
              </p>
              <div className="h-10" />
              <p>
                <b>Keep your account alive</b>
              </p>
              <p>
                Nothing is worst, in reputational terms, than a careless social
                network account.
              </p>
              <p>So, you need to keep a regular post policy.</p>
              <p>
                Of course an image post catches the eye much more than a text
                and if this image keeps visual cohesion with your website you
                are in the right way.
              </p>
              <p>
                Our team is able to produce these images and post them, on your
                behalf, on your social networks.
              </p>
              <div className="h-10" />
              <p>
                <b>Advertisement</b>
              </p>
              <p>
                Another possibility is to advertise in social networks using a
                high level of detail in finding you specific audience.
              </p>
              <p>
                There are a numberless options of values and number of printings
                and we can help you to find a good balance between your budget
                and the desired visibility.
              </p>
              <div className="h-10" />
              <p>
                <b>Some accounts currently attended by us</b>
              </p>
              <div className="h-10" />
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["dauco"])}
                    backgroundColor={false}
                    alt="daucodesigns - Barcelona"
                  />
                  <div className="text-center py-2">
                    <p>daucodesigns</p>
                    <p>Barcelona - Spain</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/daucodesigns"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/dauco.designs/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["littlel"])}
                    backgroundColor={false}
                    alt="LittleL - Sydney"
                  />
                  <div className="text-center py-2">
                    <p>LittleL</p>
                    <p>Sydney - Australia</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/LittleLMonaValeBurgers"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/littlelmonavale/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
                <div className=" text-center">
                  <GatsbyImage
                    image={getImage(data["spkbr"])}
                    backgroundColor={false}
                    alt="Speckle Park Brasil"
                  />
                  <div className="text-center py-2">
                    <p>Speckle Park Brasil</p>
                    <p>São Paulo - Brazil</p>
                  </div>
                  <div className="text-center">
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.facebook.com/SpeckleParkBrasil"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"25px"} icon={facebookSquare} />
                    </a>
                    <a
                      className="mx-2 btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring  focus:bg-blue-800 px-2 py-2"
                      color="info"
                      href="https://www.instagram.com/speckleparkbrasil/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"25px"} icon={instagram} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-10" />
          <div className="h-10" />
          <div className="max-w-full">
            <div
              className="col-span-12"
              style={{ margin: "0 auto", maxWidth: "500px" }}
            >
              <Carousel
                images={data.socialMediaFiles.edges}
                alt="social media"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-8" />
      <div className="text-center">
        <span className="text-2xl">
          Ready to work together?
          <br />
          <br />
        </span>
        <Link
          className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
          to="/en/lets-work-together/?service=social-media"
        >
          let's start a social media project
        </Link>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN

export const query = graphql`
  {
    socialMediaFiles: allFile(
      filter: { relativePath: { regex: "/social-media/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 500)
          }
        }
      }
    }
    dauco: file(relativePath: { eq: "costumers/Dauco.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
    littlel: file(relativePath: { eq: "costumers/LittleL.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
    spkbr: file(relativePath: { eq: "costumers/SPKBR.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 248)
      }
    }
  }
`
